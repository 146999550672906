import './OrderStatus.css';
import StatusBar from './StatusBar';
import React from "react";
import { useParams } from "react-router-dom";
import { APIURL } from "./constants";
import { parseTime } from './utilities';

function OrderStatus({ orderData }) {
  let statusPhrase = "being prepared";
  let etaPhrase = "You order will be ready";
  let nowTime = new Date(Date.now());
  console.log("nowTime", nowTime)
  let progress = (nowTime - orderData['timeOfArrivalDate']) / (orderData['eta'] - orderData['timeOfArrivalDate']);
  // progress = progress >= 1 ? 100 : progress < 0 ? 3: progress * 100;
  progress = 3;
  let etaEstimate = Math.round((orderData['eta'] - nowTime) / 60000);
  let etaRange = "";
  if (etaEstimate <= 0){
    etaRange = " any minute now";
  }
  else {
    etaRange = ' in ' + String(Math.max(0, etaEstimate - 4)) + ' to ' + String(Math.max(0, etaEstimate + 4)) + ' minutes';
  }

  console.log('orderData:', orderData)
  return (
    <div className='order-status'>
      <p>Order #{ orderData["orderNumber"] }</p>
      <p className='order-status-restaurant-name'>Mo Betta's Hawaiian</p>
      <p className="order-status-phrase">Your order is { statusPhrase }</p>
      <div className="order-status-bar-labels">
        <span className="order-status-time-of-arrival">{ orderData["timeOfArrival"] }</span>
        <span className="order-status-eta">{ orderData["etaString"] }</span>
      </div>
      <StatusBar progress={progress} />
      <p className="order-status-eta-phrase">{ etaPhrase }<span className="order-status-eta-range">{ etaRange }</span></p>
    </div>
  );
}

export default OrderStatus;