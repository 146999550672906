import './AppPromotion.css';
import logo from './logo.png';
import React, { useState, useContext } from "react";

function AppPromotion() {
  return (
  	<div className='app-promotion'>
  		<img src={logo} className="app-promotion-logo" />
  		<button className='app-promotion-open-button'>OPEN</button>
    </div>
  );
}

export default AppPromotion;