import './App.css';
import AppPromotion from './AppPromotion';
import OrderStatus from './OrderStatus';
import OrderDetails from './OrderDetails';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { APIURL } from './constants';
import { parseTime, addMinutes } from './utilities';

function App () {
  return (
    <Router>
      <Routes>
        <Route exact path='/:id' element={
        <AppHelper />
      }></Route>
    </Routes>
    </Router>
  );
}

function AppHelper () {
  const routeParams = useParams();
  const [orderDataNew, setOrderDataNew] = useState('');
  const loadOrder = async () => {
    const URL = APIURL + '/get_order';
    const params = {
      order_id: routeParams.id
    };
    const options = {
      method: 'POST',
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' }
    };
    await fetch(URL, options).then(resp => {
      if (resp.status === 200) {
        return resp.json();
      } else {
        console.log('Status: ' + resp.status);
        return Promise.reject('server');
      }
    }).then(dataJson => {
      dataJson.timeOfArrivalDate = addMinutes(parseTime(dataJson.timeOfArrival), -24 * 60);
      dataJson.eta = addMinutes(dataJson.timeOfArrivalDate, 20); // Todo: remove once the ETA logic is implemented on the backend
      const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
      dataJson.etaString = (new Date(dataJson.eta - tzoffset)).toISOString().split('T')[1].slice(0, 5); // Todo: remove once the ETA logic is implemented on the backend
      setOrderDataNew(dataJson);
    }).catch(err => {
      if (err === 'server') return;
      console.log(err);
    });
  };

  useEffect(() => {
    loadOrder();
  }, []);
  return (
    <div className="app">
    <AppPromotion />
    <OrderStatus orderData={orderDataNew} />
    <div className="divider"></div>
    <OrderDetails orderData={orderDataNew} />
    </div>
  );
}

export default App;
