import './OrderDetails.css';
import React, { useState, useContext } from "react";

function OrderDetails({ orderData }) {
  if(orderData != ""){
    const OrderItems = orderData["items"].map((orderItemData, index) =>
      <OrderItem key={index} orderItemData={orderItemData} />
    );
    return (
      <div className='order-details'>
        { OrderItems }
      </div>
    );
  } else {
    return (
      <></>
    )
  }
}

function OrderItem({ orderItemData }){
  const orderItemOptions = orderItemData["options"].map((orderItemOptionData, index) =>
    <OrderItemOption key={index} orderItemOptionData={orderItemOptionData} />
  );
	return (
		<div className='order-item'>
			<p className='order-item-text'>{ orderItemData["quantity"] + "x " + orderItemData["title"] }</p>
      { orderItemOptions }
		</div>
	)
}

function OrderItemOption({ orderItemOptionData }){
  console.log("orderItemOptionData['value']:", orderItemOptionData["value"])
  const orderItemOptionValues = orderItemOptionData["value"].map((orderItemOptionValuesData, index) =>
    <OrderItemOptionValue key={index} orderItemOptionValuesData={orderItemOptionValuesData} />
  );
  console.log("orderItemOptionValues:", orderItemOptionValues)
  return (
    <div className='order-item-option'>
      <p className='order-item-option-text'>{ orderItemOptionData["title"] }</p>
      { orderItemOptionValues }
    </div>
  )
}

function OrderItemOptionValue({ orderItemOptionValuesData }){
  return (
    <div className='order-item-option-value'>
      <p className='order-item-option-value-text'>{ orderItemOptionValuesData["title"] }</p>
    </div>
  )
}

export default OrderDetails;