function parseTime(time){
  let now = new Date(Date.now());
  let nowDateTime = now.toISOString();
  let target = new Date(nowDateTime.split('T')[0] + 'T' + time + ':00');
  return target;
}

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes*60000);
}

export { parseTime, addMinutes };